.disclaimer-block{
    padding: v(60) v(70);
    color: #fff;

    h3{
        font-size: v(48);
        margin-bottom: v(60);
        text-align: center;
    }
    .disclaimer-conetnt{
        font-size: v(16);
        line-height: 1.5em;
        white-space: pre-wrap;
    }
}



@media (max-width: 1250px) {
    $fontScale: 1.5;
    .disclaimer-block{
        h3{
            font-size: v(48 * $fontScale);
            margin-bottom: v(60 * $fontScale);

        }
        .disclaimer-conetnt{
            font-size: v(16 * $fontScale);
        }
    }
}

@media (max-width: 960px) {
    .disclaimer-block{
        padding-top: v(240);

        h3{
            font-size: v(116);
            color: #fff;
        }
        .disclaimer-conetnt{
            font-size: v(40);
        }
    }
}

@media (max-width: 560px) {
    $scale: 2;

    .disclaimer-block{
        padding-top: v(240 * $scale);

        h3{
            font-size: v(116 * $scale);
        }
        .disclaimer-conetnt{
            font-size: v(40 * $scale);
        }
    }
}
