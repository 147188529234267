.faq-block{
    padding: v(60) v(70);
    color: #fff;

    h3{
        font-size: v(48);
        margin-bottom: v(60);
        text-align: center;
    }
    .faq-conetnt{

        .more-line{
            margin-top: v(50);
            font-size: v(16);

            a{
                color: #1aa763;
                text-decoration: underline;
            }
        }
        .el-collapse{
            border: none;
        }
        .el-collapse-item{
            margin-bottom: 20px;
        }
        .el-collapse-item__header{
            background-color: #43876c;
            border: none;
            color: #fff;
            position: relative;


            &.is-active{
                background-color: #1ba663;
            }

            .title{
                width: 100%;
                padding: 0 20px;
                padding-right: 40px;
                // position: relative;
                line-height: 1.2em;

                .header-icon{
                    position: absolute;
                    right: 20px;
                    top: 17px;
                }
            }
        }
        .el-collapse-item__wrap{
            background-color: transparent;
            color: #fff;
            border: none;
        }
        .el-collapse-item__content{
            color: #fff;
            padding: 20px;
            padding-bottom: 0px;
        }
        .el-collapse-item__arrow{
            display: none;
        }
    }
}

@media (max-width: 960px) {
    .faq-block{
        h3{
            font-size: v(200);
        }
        .faq-conetnt{
            .more-line{
                font-size: 12px;
            }
        }
    }
}
