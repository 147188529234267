










































































.ie-tips{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 99999;
    background: #fff;
    color: #000;
    font-size: 16px;
    text-align: center;

    a, span{
        color: #02904e;
        font-weight: bold;
        line-height: 100px;
    }
    span{
        text-decoration: underline;
        cursor: pointer;
    }
}
