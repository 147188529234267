.speakers-content{
    padding-left: v(377);
    padding-right: v(377);
    padding-bottom: v(140);
}
.speakers-block{
    width: v(1266);
    margin: 0 auto;
    margin-top: v(50);
    height: v(765);
    position: relative;

    > .iconfont{
        position: absolute;
        top: 50%;
        z-index: 10;
        color: #ffffff;
        font-size: v(100);
        margin-top: v(-120);
        cursor: pointer;
        &:hover{
            color: #09a557;
        }
    }
    .icon-left{
        left: v(-80);
    }
    .icon-right{
        right: v(-80);
    }

    .speakers-siwper{
        position: absolute!important;
        left: 0;
        bottom: v(-60);
        z-index: 5;
        width: 100%;
        height: v(380);
        // overflow-x: hidden;
        // overflow-y: sataic;

        .swiper-slide{
            padding-top: v(32);

            .cover{
                position: relative;
                width: v(190);
                height: v(236);
                margin: 0 auto;
                cursor: pointer;
                .avatar{
                    position: relative;
                    z-index: 3;
                    width: 100%;
                    height: 100%;
                    background-size: cover;
                    background-position: center center;
                    border: 1px solid #09a557;
                }
                .name{
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: 5;
                    padding: v(13) v(25);
                    background-color: #09a557;
                    font-size: v(20);
                    font-weight: 500;
                    color: #fff;
                }
                .info{
                    position: absolute;
                    top: 100%;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    background-color: #003f2c;
                    padding: v(12) v(15);
                    font-size: 12px;
                    color: #fff;
                    @include tsm(translateY(-150%));
                    opacity: 0;
                    @include tsn;

                    > span{
                        width: 100%;
                        overflow : hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        height: 31px;
                        transform: scale(.9);
                    }
                }
                .iconfont{
                    position: absolute;
                    bottom: calc(100% + 5px);
                    left: 50%;
                    z-index: 1;
                    color: #09a557;
                    font-size: v(20);
                    margin-left: v(-10);
                    display: none;
                }
                &:hover{

                    > .info{
                        @include tsm(translateY(0));
                        opacity: 1;
                    }
                }
            }
        }
        .swiper-slide-active{
            .cover{
                .avatar{
                    border-width: 2px;
                }
                .info{
                    @include tsm(translateY(0));
                    opacity: 1;
                }
                .iconfont{
                    display: block;
                }
            }
        }
    }
}
.speakers-block-phone{
    display: none;
}
.speaker-detail{
    > .left{
        position: absolute;
        bottom: v(53);
        left: 0;
        width: v(656);
        height: v(773);
        background-size: 100% 100%;
        background-position: center center;

        img{
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
            display: block;
            width: 100%;
            height: 100%;
            transition: .3s;
        }
        .img-show{
            opacity: 1;
        }
    }
    > .right{
        position: absolute;
        top: 0;
        right: 0;
        height: v(450);
        width: v(600);

        > div{
            position: absolute;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
        }
        .name{
            font-size: v(40);
            color: #003F2D;
            font-weight: 600;
            // margin-top: v(50);
        }
        .intro{
            font-size: v(20);
            color: #003F2D;
        }
        .info{
            font-size: v(16);
            margin-top: v(10);
            color: #333333;
        }
    }


}






@media (max-width: 1250px) {
    $fontScale: 1.3;

    .speakers-content{
        padding-right: v(100);
    }
    .speaker-detail {

        > .left{
            width: v(656 * .8);
            height: v(773 * .8);
        }
        > .right {
            width: v(700);
            height: v(470);
            > div{

                transform: translateY(0);
                top: auto;
                bottom: v(20);
            }
            .name{
                font-size: v(40 * $fontScale);
            }
            .intro{
                font-size: v(18 * $fontScale);
            }
            .info{
                font-size: v(15 * $fontScale);
                margin-top: v(10);
            }
        }
    }
}

@media (max-width: 960px) {

    .speakers-block{
        display: none;
    }
    .speakers-content{
        padding-left: 0;
        padding-right: 0;
        width: 86%;
        margin: 0 auto;
    }
    .speakers-block-phone{
        display: block;
        position: relative;

        > .iconfont{
            position: absolute;
            top: 300px;
            z-index: 10;
            color: #09a557;
            font-size: v(200);
            margin-top: v(-120);
            cursor: pointer;
            &:hover{
                color: #09a557;
            }
        }
        .icon-left{
            left: v(-120);
        }
        .icon-right{
            right: v(-120);
        }
        .speakers-siwper{
            .speakers-phone-pagination{
                bottom: 0;
                left: 0;
                text-align: center;

                .swiper-pagination-bullet{
                    border-radius: 0;
                    width: v(24);
                    height: v(24);
                }
                .swiper-pagination-bullet.swiper-pagination-bullet-active{
                    background-color: #3e9069;
                }
            }

            .swiper-slide{
                width: 100%;
                position: relative;

                .cover{
                    padding-bottom: v(50);
                }

                .avatar{
                    width: v(820);
                    height: v(966);
                    margin: 0 auto;
                    background-size: 100% 100%;
                }
                .line1{
                    width: 100%;
                    color: #fff;
                    background-color: #00a658;
                    text-align: center;
                    padding: v(37) v(47);
                    max-width: 500px;
                    margin: 0 auto;

                    .name{
                        font-size: v(85);
                    }
                    .intro{
                        font-size: v(50);
                    }
                }
                .line2{
                    width: 100%;
                    background-color: #f3f5f4;
                    padding: v(20) v(47);
                    max-width: 500px;
                    margin: 0 auto;
                    color: #333333;
                }
            }
        }
    }
}

@media (max-width: 560px) {
    $scale: 2;

    .speakers-block-phone{
        .speakers-siwper{
            .speakers-phone-pagination{
                .swiper-pagination-bullet{
                    width: v(24 * $scale);
                    height: v(24 * $scale);
                }
            }

            .swiper-slide{
                .cover{
                    padding-bottom: v(50 * $scale);
                }

                .avatar{
                    width: v(820 * $scale);
                    height: v(966 * $scale);
                }
                .line1{
                    padding: v(37 * $scale) v(47 * $scale);


                    .name{
                        font-size: v(85 * $scale);
                    }
                    .intro{
                        font-size: v(50 * $scale);
                    }
                }
                .line2{
                    padding: v(20 * $scale) v(47 * $scale);
                }
            }
        }
    }

}
