.home-content{
    height: 100vh;
    padding-top: 0;
    padding-bottom: 0;



    .next-btn{
        position: absolute;
        z-index: 90;
        bottom: v(60);
        left: 50%;
        width: v(55 * .95);
        height: v(70 * .95);
        display: block;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px solid #326258;
        cursor: pointer;
        @include tsn;

        i{
            position: relative;
            top: 0;
            font-size: v(20);
            color: #69BE28;
            @include tsn;
            animation: 3s breathing infinite linear;

        }

        &:hover{
            bottom: v(95);
            i{
                top: v(7);
            }
        }
    }
    .prev-btn i{
        @include tsm(rotate(180deg))
    }
    .svg-bg{
        position: absolute;
        width: 177.7777vh;
        height: 100vh;
        top: 50%;
        left: 50%;
        @include tsm(translate(-50%, -50%));
        z-index: -1;
    }

    .anima-bg{
        position: absolute;
        top: 50%;
        left: 50%;
        @include tsm(translate(-50%, -50%));
        width: v(1920);
        height: v(1080);
        z-index: -1;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .anima-bg2{
        > .kv-ani{
            position: relative;
            width: v(900);
            height: v(900);
            position: relative;
            transform-origin: center;
            transform: scale(.8);
            margin-top: v(80);

            > .debris{
                position: absolute;
                background-size: 100% 100%;
                transition: .4s cubic-bezier(0,-0.01, 0.77, 1.67);
                transform: scale(0);
                transform-origin: center center;
            }
            @for $i from 1 to 35{
                .debris:nth-child(#{$i}){
                    z-index: $i;
                    transition-delay: $i * .1s;
                    animation-delay: $i * .3s;
                }
            }
            > .debris:nth-child(1){
                left: v(207);
                top: v(76);
                width: v(308);
                height: v(297);
            }
            > .debris:nth-child(2){
                left: v(514);
                top: v(120);
                width: v(189);
                height: v(227);
            }
            > .debris:nth-child(3){
                left: v(586);
                top: v(303);
                width: v(58);
                height: v(78);
            }
            > .debris:nth-child(4){
                left: v(496);
                top: v(46);
                width: v(59);
                height: v(100);
            }
            > .debris:nth-child(5){
                left: v(320);
                top: v(206);
                width: v(221);
                height: v(183);
            }
            > .debris:nth-child(6){
                left: v(174);
                top: v(508);
                width: v(219);
                height: v(273);
            }
            > .debris:nth-child(7){
                left: v(101);
                top: v(463);
                width: v(269);
                height: v(140);
            }
            > .debris:nth-child(8){
                left: v(353);
                top: v(603);
                width: v(132);
                height: v(200);
            }
            > .debris:nth-child(9){
                left: v(480);
                top: v(518);
                width: v(197);
                height: v(258);
            }
            > .debris:nth-child(10){
                left: v(388);
                top: v(501);
                width: v(161);
                height: v(203);
            }
            > .debris:nth-child(11){
                left: v(543);
                top: v(485);
                width: v(230);
                height: v(182);
            }
            > .debris:nth-child(12){
                left: v(670);
                top: v(406);
                width: v(187);
                height: v(82);
            }
            > .debris:nth-child(13){
                left: v(576);
                top: v(444);
                width: v(138);
                height: v(65);
            }
            > .debris:nth-child(14){
                left: v(530);
                top: v(407);
                width: v(92);
                height: v(71);
            }
            > .debris:nth-child(15){
                left: v(707);
                top: v(630);
                width: v(74);
                height: v(47);
            }
            > .debris:nth-child(16){
                left: v(447);
                top: v(442);
                width: v(49);
                height: v(52);
            }
            > .debris:nth-child(17){
                left: v(296);
                top: v(354);
                width: v(24);
                height: v(23);
            }
            > .debris:nth-child(18){
                left: v(518);
                top: v(362);
                width: v(41);
                height: v(35);
            }
            > .debris:nth-child(19){
                left: v(317);
                top: v(389);
                width: v(68);
                height: v(48);
            }
            > .debris:nth-child(20){
                left: v(700);
                top: v(229);
                width: v(38);
                height: v(31);
            }
            > .debris:nth-child(21){
                left: v(674);
                top: v(269);
                width: v(189);
                height: v(103);
            }
            > .debris:nth-child(22){
                left: v(62);
                top: v(272);
                width: v(195);
                height: v(133);
            }
            > .debris:nth-child(23){
                left: v(81);
                top: v(130);
                width: v(125);
                height: v(118);
            }
        }

        > .kv-ani-show{
            > .debris{
                transform: scale(1);
            }
        }
        > .kv-ani-keep{
            > .debris{
                animation: debris 5s infinite linear;
            }
        }
    }
    .anima-bg3{
        > .item{
            position: absolute;
            top: 50%;
            left: 50%;
            width: v(1222);
            height: v(640);
            margin-left: v(1222 / -2);
            margin-top: v(640 / -2);
            background-size: 100% 100%;
        }

        @for $i from 1 to 12{
            .item#{$i}{
                z-index: $i;
                animation-delay: .12s * $i;
            }
        }
    }
    .anima-bg4{
        > .item{
            position: absolute;
            top: 50%;
            left: 50%;
            width: v(930);
            height: v(667);
            margin-left: v(930 / -2);
            margin-top: v(667 / -2);
            background-size: 100% 100%;
        }

        @for $i from 1 to 16{
            .item#{$i}{
                animation-delay: .12s * $i;
            }
        }
    }
    .anima-bg5{
        > .item{
            position: absolute;
            width: v(120);
            height: v(150);
            top: 50%;
            left: 300px;
            background-color: #599180;
            transform-style: preserve-3d;
            // transform: translate(0, 0) rotateZ(10deg);
        }
        > .item1{
            top: v(341);
            left: v(537);
            animation: 15s suspension1 linear infinite alternate;
        }
        > .item2{
            top: v(192);
            left: v(1082);
            animation: 15s suspension2 linear infinite alternate;
        }
        > .item3{
            top: v(781);
            left: v(641);
            animation: 15s suspension3 linear infinite alternate;
        }
    }

    .anima-bg6{
        z-index: -2;
        > .item{
            position: absolute;
            transform-style: preserve-3d;
            transition-property: transform;
        }
        > .item1{
            transform-style: preserve-3d;
            top: v(280);
            left: 50%;
            width: v(600);
            margin-left: v(-300);
            height: v(550);
            background-color: #599180;
            border: 1px solid #9df1dc;
            @include linearGradient(-145deg, rgba(#5a9180, .9) 0%, rgba(#5a9180, 0) 80%);
            transform-origin: center center;
            z-index: 3;
            transform: perspective(v(1300)) translate3d(0px, 0px, v(0)) rotateX(0deg) rotateY(0deg);
            opacity: 1;

            &.run{
                animation: p4rotate1 .7s 0s ease-out forwards;
            }

            // &::before{
            //     position: absolute;
            //     content: '';
            //     left: -10%;
            //     top: 50%;
            //     width: v(693);
            //     height: v(14);
            //     background-size: 100% 100%;
            //     display: block;
            //     background-image: url(https://img.mofyi.com/202108/1617555510998.png);
            // }
        }
        > .item2{
            transform-style: preserve-3d;
            top: v(280);
            left: 50%;
            width: v(600);
            margin-left: v(-300);
            height: v(550);
            background-color: #599180;
            @include linearGradient(-145deg, rgba(#5a9180, .9) 0%, rgba(#5a9180, 0) 80%);
            transform-origin: center center;
            z-index: 1;
            transform: perspective(v(1300)) translate3d(0px, 0px, v(0)) rotateX(0deg) rotateY(0deg);

            &.run{
                animation: p4rotate2 .7s .3s ease-out forwards;
            }
        }
        > .item3{
            transform-style: preserve-3d;
            top: v(280);
            left: 50%;
            width: v(600);
            margin-left: v(-300);
            height: v(550);
            background-color: #599180;
            @include linearGradient(-145deg, rgba(#5a9180, .9) 0%, rgba(#5a9180, 0) 80%);
            transform-origin: center center;
            z-index: 2;
            transform: perspective(v(1300)) translate3d(0px, 0px, v(0)) rotateX(0deg) rotateY(0deg);

            &.run{
                animation: p4rotate3 .7s .15s ease-out forwards;
            }

        }
        > .item4{
            transform-style: preserve-3d;
            top: v(480);
            left: 50%;
            width: v(700);
            height: v(300);
            margin-left: v(-350);
            border-radius: 50%;
            border: 1px solid #a39670;
            transform-origin: center center;
            z-index: 2;
            transform: perspective(v(1300)) translate3d(0px, 0px, v(0)) rotateX(30deg) rotateY(0deg);
        }
    }
}
.home-swiper{
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    @include userSelect;

    .swiper-slide{
        position: relative;
        padding-top: v(80);
        padding-bottom: v(80);
        font-size: v(50);
        color: #fff;
        text-align: center;
        @include userSelect;

        .kv{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
        .kv-img{
            width: v(760);
            display: block;
            margin-right: v(200);
        }

        .play-btn, .btns{
            display: none;
        }

        .video-block{
            position: absolute;
            left: v(540);
            top: v(230);
            // top: 50%;
            // margin-top: v(-301);
            width: 50* 1.777777777vh;
            height: 50vh;
            background-color: #000;
            border: 2px solid $themeColor;
            > .video-parent{
                width: 100%;
                height: 100%;
                background-color:$themeColor;
                position: relative;
                #dplayer{
                    font-size: 14px;
                    width: 100%;
                    height: 100%;
                    display: block;

                    .dplayer-mobile-play{
                        display: none!important;
                        opacity: 0;
                    }

                }
                .v-play-btn{
                    @include absoluteCenter;
                    color: #fff;
                    z-index: 10;
                    opacity: 0;
                    visibility: hidden;
                    transition: .3s;
                    i{
                        font-size: 70px;
                        text-shadow: 0 0 2px rgba(#000, .13);
                        cursor: pointer;
                    }
                }
                .v-play-btn-show{
                    opacity: 1;
                    visibility: visible;
                }
                // video{
                //     width: 100%;
                //     height: 100%;
                //     display: block;

                //     // &::-webkit-media-controls-toggle-closed-captions-button {
                //     //     display: none;
                //     // }
                // }
            }
        }
    }

    .nr-title{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        align-items: center;
        text-align: left;
        display: flex;

        > div{
            display: inline-block;
            position: relative;
            max-width: v(1000);
            min-width: v(500);
        }
        .time{
            text-align: right;
            font-size: v(65);
        }
        .title{
            font-size: v(120);
            font-weight: bold;
            border-bottom: 2px solid #09a557;
        }
        .nr{
            width: v(500);
        }
        .info{
            width: 100%;
            font-size: v(15);
            padding-top: v(25);
            padding-left: v(100);
            white-space: pre-wrap;
        }
        a{
            position: absolute;
            font-size: v(26);
            color: #fff;
            border-bottom: 2px solid #09a557;
            line-height: 1.5em;
            display: inline-block;
            margin-left: v(100);
            margin-top: v(20);
        }
    }
    .page3 .nr-title{
        justify-content: flex-end;
        padding-right: v(364);

    }
    .page4 .index-agenda-p{
        position: absolute;
        top: v(200);
        left: 0;
        padding-left: v(354);
        padding-right: v(279);
        width: 100%;
        // height: 100%;
        text-align: left;
        font-size: 14px;
        transform: translateX(100px);
        opacity: 0;
        visibility: hidden;
        @include tsn;

        .agenda-swiper{
            margin-top: v(20);
        }
    }
    .page4 .index-agenda-p-show{
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
    }
    .page4 .nr-title{
        justify-content: flex-end;
        padding-right: v(264);

    }
    .page5 .nr-title{
        justify-content: flex-end;
        padding-right: v(436);

    }
    .page6 .nr-title{
        justify-content: center;
        > div{

            .title{
                text-align: center;
            }
            .info{
                padding-left: 0;
                width: 50%;
                display: inline-block;
            }
            .btn-p{
                > .theme-btn{
                    display: inline-block;
                    width: 45%;
                    vertical-align: top;
                    margin-top: v(30);
                    margin-left: 5%;
                }
            }
        }
    }
}

.home-time{
    display: none;
}

.video-mask{
    position: fixed;
    top: -150%;
    opacity: 0;
    left: 0;
    z-index: 130;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, .7);
    display: flex;
    align-items: center;
    justify-content: center;

    i{
        position: absolute;
        top: 20px;
        right: 20px;
        color: #fff;
        font-size: 25px;
    }

    > div{
        width: 90%;

        > video{
            width: 100%;
            display: block;
        }
    }
}
.video-mask-show{
    top: 0;
    opacity: 1;
}




@media (max-width: 1250px) {
    $fontScale: 1.5;
    .intro-content{
        p{
            font-size: 13px;
        }
    }
}

@media (max-width: 960px) {
    .home-swiper .nr-title .time{
        font-size: 16px;
    }
    .home-time{
        display: block;
        position: absolute;
        top: v(150);
        left: 0;
        width: 100%;
        z-index: 3;
        color: #0e6d42;
        > p{
            font-size: v(64);
            padding-left: v(70);
            margin-bottom: v(50);
        }
        ul{
            display: flex;
            align-items: center;
            padding: 0;

            li{
                padding: v(5) 0;
                border-left: 1px solid #015129;
                text-align: center;
                width: 25%;
                strong{
                    // width: v(225* 2);
                    width: 100%;
                    font-weight: 600;
                    font-size: v(65 * 2);
                    color: #38786b;
                    line-height: 1em;
                    display: block;
                }
                span{
                    font-size: v(27 * 2);
                    display: block;
                }
            }
            li:first-child{
                border-left: none;
            }
        }
    }
    .home-swiper .swiper-slide {
        .kv{
            height: auto;
            display: block;
            margin-top: v(-250);
            > img{
                width: v(900);
                margin-right: 0;
                display: block;
                margin: 0 auto;
            }
        }
        .play-btn{
            display: block;
            width: v(1100);
            display: block;
            margin: v(60) auto;
            background-color: #04402f;
            border: none;
            outline: none;
            color: #00a657;
            height: v(150);
            margin-bottom: v(20);

            > img{
                height: v(100);
                vertical-align: middle;
                margin-right: v(50);
            }
        }
        .btns{
            width: v(1100);
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .live-btn{
                display: flex;
                justify-content: flex-end;
                width: 49%;

                .live-link {
                    width: 100%;
                    padding: v(42) 0;
                    background-color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: v(45);
                    color: #003C1F;
                    display: block;
                    text-align: center;
                    line-height: 1em;
                    background-image: linear-gradient(45deg, #78cea4 0%, #d4fee9 100%);
                    padding-left: 11px;
                    > span{
                        line-height: 1em;
                    }
                    >.dian {
                        display: inline-block;
                        width: 8px;
                        height: 8px;
                        background-color: #cc0000;
                        border-radius: 50%;
                        vertical-align: top;
                        margin-left: 3px;

                    }
                }
                span.live-link{
                    user-select: none;
                    cursor: default;
                    background-image: none;
                    color: #4b4b49;
                    border: 1px solid #4b4b49;
                    background-color: transparent;
                }
            }

            .local-btn{
                > .live-link{
                    background-image: none;
                    background-color: #0ea15d;
                    color: #fff;
                    padding-left: 0;
                }
            }
        }
    }
    .home-content{
        .anima-bg2{
            position: relative;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            transform: none;
            padding-top: v(550);

            .kv-ani{
                transform-origin: top center;
                transform: scale(1.35);
            }

            > img{
                position: relative;
                top: 0;
                left: 0;
                width: v(1000);
                transform: none;
                display: block;
                margin: 0 auto;
            }
        }
        .anima-bg3 > .item{
            width: v(1222 * 2);
            height: v(640 * 2);
            margin-left: v(1222 / -2 * 2);
            margin-top: v(640 / -2 * 2);
        }
        .anima-bg4 > .item{
            width: v(930 * 2);
            height: v(667 * 2);
            margin-left: v(930 / -2 * 2);
            margin-top: v(667 / -2 * 2);
        }
        .anima-bg5{
            width: 100%;
            height: 100%;
            > .item{
                width: v(120 * 2);
                height: v(150 * 2);
            }
            > .item1{
                top: v(507 * 2);
                left: v(66 * 2);
            }
            > .item2{
                top: v(155 * 2);
                left: v(735 * 2);
            }
            > .item3{
                top: v(700 * 2);
                left: v(421 * 2);
            }
        }

        .anima-bg6 {
            transform: translate(-50%, -50%) scale(2);
            transform-origin: center;
        }

        .next-btn{
            left: auto;
            right: v(50);
            bottom: v(80);
            width: v(80);
            height: v(100);

            i{
                font-size: v(40);
                color: #fff;
            }

            &:hover{
                bottom: v(80);
                i{
                    top: 0;
                }
            }
        }
    }

    .home-swiper .nr-title{
        align-items: flex-start;
        padding-top: v(1300);
        text-align: center;
        padding-right: 0!important;
        justify-content: center!important;

        > div{
            max-width: none!important;
            min-width: 0!important;
        }

        .title{
            font-size: v(125);
        }
        .time{
            text-align: center;
        }
        .nr{
            width: v(820);
            font-size: 12px;
        }

        .info{
            padding-left: 0;
            font-size: 12px;
        }

        a{
            display: block;
            position: static;
            width: v(820);
            height: v(115);
            border: 1px solid #0a5039;
            color: #fff;
            margin: 0;
            font-size: 14px;
            margin-top: v(70);
            line-height: v(115);
        }
    }

    .home-swiper .page6 .nr-title{
        a{
            display: block;
            position: static;
            width: auto;
            height: auto;
            border: none;
            color: #02a557;
            margin-top: v(70);

            .theme-btn{
                width: v(820);
                height: v(118);

                font-size: v(50);

                i{
                    font-size: v(55);
                }
            }
        }
    }
}

@media (max-width: 560px) {
    $scale: 2;

    .home-time{
        top: v(150 * 2);
        > p{
            padding-left: v(140);
        }

        ul{
            padding: 0;
        }
    }
    .home-swiper .swiper-slide {
        .kv{
            margin-top: v(-250 * $scale);
            // margin-top: -7.166667rem;
            > img{
                width: v(1400);
            }
        }
        .play-btn{
            width: v(1350);
            margin: v(60 * $scale) auto;
            height: v(200);
            font-size: v(90);
            margin-bottom: v(30);

            > img{
                height: v(100);
                margin-right: v(50);
            }
        }

        .btns{
            width: v(1350);
            margin: 0 auto;

            .live-btn{

                width: 49%;

                .live-link {
                    padding: v(60) 0;
                    font-size: v(80);

                }
            }
        }
    }
    .home-content{
        .anima-bg2{
            // padding-top: v(750);
            padding-top: 4.25rem;
            .kv-ani{
                transform-origin: top center;
                transform: scale(2);
            }
            > img{
                width: v(1400);
            }
        }
        .anima-bg3 > .item{
            width: v(1222 * 2);
            height: v(640 * 2);
            margin-left: v(1222 / -2 * 2);
            margin-top: v(640 / -2 * 2);
        }
        .anima-bg4 > .item{
            width: v(930 * 2);
            height: v(667 * 2);
            margin-left: v(930 / -2 * 2);
            margin-top: v(667 / -2 * 2);
        }
        .anima-bg5{
            > .item{
                width: v(120 * 2.5);
                height: v(150 * 2.5);
            }
            > .item1{
                top: v(507 * 2);
                left: v(66 * 2);
            }
            > .item2{

                top: v(555 * 2);
                left: v(705 * 2);
            }
            > .item3{
                top: v(900 * 2);
                left: v(421 * 2);
            }
        }

        .next-btn{
            right: v(50 * $scale);
            bottom: v(80 * $scale);
            width: v(80 * $scale);
            height: v(100 * $scale);

            i{
                font-size: v(40 * $scale);
            }

            &:hover{
                bottom: v(80 * $scale);
                i{
                    top: 0;
                }
            }
        }
    }

    .home-swiper .nr-title{
        padding-top: v(700 * $scale);

        .title{
            font-size: v(125 * $scale);
        }

        .nr{
            width: v(820 * $scale);
        }

        a{
            width: v(820 * $scale);
            height: v(115 * $scale);
            margin-top: v(70 * $scale);
            line-height: v(115 * $scale);
        }
    }

    .home-swiper .page6 .nr-title{
        a{

            margin-top: v(70 * $scale);

            .theme-btn{
                width: v(820 * $scale);
                height: v(118 * $scale);
                font-size: v(50 * $scale);

                i{
                    font-size: v(55 * $scale);
                }
            }
        }
        > div .info{
            width: 80%;
            margin-top: v(60);
        }
    }


}
