.intro-content{
    color: #fff;
    padding-left: v(420);
    padding-right: v(420);
    line-height: 1.5em;

    > p{
        padding: v(150);
        font-size: 15px;
    }
}


@media (max-width: 1250px) {
    $fontScale: 1.5;
    .intro-content{
        p{
            font-size: 13px;
        }
    }
}

@media (max-width: 960px) {
    .intro-content{
        padding-left: 0;
        padding-right: 0;

        p{
            text-align: center;
            font-size: 16px;
        }
    }
}

@media (max-width: 560px) {

}
