.theme-btn{
    color: #fff;
    background-color: $themeColor;
    border: 1px solid $themeColor;
    @include tsn;
    line-height: 1em;
    padding: v(12) v(20);
    font-size: v(18);
    cursor: pointer;
    border: none;
    outline: none;

    i{
        font-size: v(20);
        margin-right: v(3);
    }
    &:hover{
        background-color: $activeColor;
        border-color: $activeColor;
    }
}

.theme-btn.light{
    background-color: #02a556;
    border: 1px solid #02a556;
    &:hover{
        background-color: $activeColor;
        border-color: $activeColor;
    }
}
.theme-btn.dark{
    background-color: $themeColor;
    border: 1px solid $themeColor;
    &:hover{
        background-color: $activeColor;
        border-color: $activeColor;
    }
}
.theme-btn.border{
    border: 1px solid $themeColor;
    background-color: transparent;

    &:hover{
        background-color: $activeColor;
        border-color: $activeColor;
    }
}
.theme-btn.disable{
    background-color: #aaa;
    border-color: #aaa;

    &:hover{
        background-color: #aaa;
        border-color: #aaa;
        color: #fff;
    }
}

@media (max-width: 1250px) {
    $fontScale: 1.3;

    .theme-btn{
        font-size: v(18 * $fontScale);
        i{
            font-size: v(20 * $fontScale);
        }
    }
}

@media (max-width: 960px) {
    $fontScale: 1.8;

    .theme-btn{
        padding: v(12 * $fontScale) v(20 * $fontScale);
        font-size: v(18 * $fontScale);
        i{
            font-size: v(20 * $fontScale);
        }
    }
}

@media (max-width: 560px) {
    $fontScale: 3.6;

    .theme-btn{
        padding: v(18 * $fontScale) v(20 * $fontScale);
        font-size: v(18 * $fontScale);
        i{
            font-size: v(20 * $fontScale);
        }
    }
}
