.cbre-checkbox{

    .el-checkbox__input{
        vertical-align: top;
        margin-top: -1px;
    }
    .el-checkbox__inner{
        background-color: #09a557;
        border: none;
        border-radius: 0;
        width: 20px;
        height: 20px;
        display: block;
    }
    .el-checkbox__inner::after{
        transform: none;
        width: 50%;
        height: 50%;
        border: none;
        background-color: transparent;
        top: 25%;
        left: 25%;
        transition: all .2s;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner::after{
        transform: none;
        width: 50%;
        height: 50%;
        border: none;
        background-color: #fff;
        top: 25%;
        left: 25%;
    }
    .el-checkbox__label{
        font-size: 14px;
    }

    .text{
        width: 100%;
        word-wrap: break-word;
        white-space: normal;
        color: #fff;

        a{

            color: #1aa763;
            border-bottom: 1px solid #1aa763;
        }
    }
}


.cbre-radio{
    .el-radio__inner{
        width: 22px;
        height: 22px;
        border-radius: 0;
        border: 1px solid #61c796;
    }
    .el-radio__input.is-checked .el-radio__inner{
        background-color: #fff;
        border: 1px solid #61c796;
    }
    .el-radio__inner::after{
        width: 14px;
        height: 14px;
        border-radius: 0;
        background: linear-gradient(-40deg, #37D95D, #00A657);
    }

    .el-radio__label{
        font-size: 14px;
        color: #43876d;
    }
    .el-radio__input.is-checked + .el-radio__label{
        color: #003f2d;
    }

    .text{
        width: 100%;
        word-wrap: break-word;
        white-space: normal;
        color: #fff;


    }
}

.el-popover{
    background-color: rgba(#1d634f, .9);
    border: none;
    border-radius: 0;
}
.el-popper .popper__arrow, .el-popper .popper__arrow::after{
    display: none;
}
