.header{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    padding: 0 v(75);
    height: v(195);
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: transform .3s;
    user-select: none;

    > .logo{
        width: v(161);
        cursor: pointer;
        position: relative;
        z-index: 3;

        > img{
            width: 100%;
            display: block;
        }
    }
    > .center{
        position: absolute;
        top: 0;
        left: 0;
        width: 90%;
        height: 100%;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        > .live-img{
            width: v(480);
            > img{
                width: 100%;
                display: block;
            }
        }
        > .time{
            color: #0e6d42;
            display: flex;
            align-items: center;
            margin-right: v(35 - 22);
            > p{
                font-size: v(24);
            }
            ul{
                display: flex;
                align-items: center;
                li{
                    padding: v(5) 0;
                    border-left: 1px solid #015129;
                    text-align: center;
                    strong{
                        width: v(104);
                        font-weight: 600;
                        font-size: v(48);
                        color: #38786b;
                        line-height: 1em;
                        display: block;
                    }
                    span{
                        font-size: v(18);
                        display: block;
                    }
                }
                li:first-child{
                    border-left: none;
                }
            }
        }
    }

    > .right{
        position: relative;
        z-index: 3;
        display: flex;

        > .user{
            margin-right: v(36);

            .login-type{
                display: flex;
                color: #fff;
                font-size: v(18);
                align-items: center;

                > p{
                    margin-right: v(20);
                }
                .icon{
                    width: v(30);
                    height: v(30);
                    border: 1px solid #037c47;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: v(10);
                    > i{
                        font-size: v(22);
                        color: #037c47;
                    }
                }
            }
        }
        > .lang{
            .theme-btn{
                width: v(46);
                padding: v(12) 0;
            }
        }
    }

    > .phone-right{
        display: none;
    }
}

.white-wrap .header{

    > .right{
        > .user{
            .login-type{
                color: #037c47;
            }
        }
        > .lang{
            .theme-btn.border{
                color: #007f42;

                &:hover{
                    color: #fff;
                }
            }
        }
    }
}

.header-scroll{
    height: v(100);
    background-color: #054031;
    transform: translateY(-120%);

    > .center > .live-img{
        width: v(300);
    }
}
.header-scroll-show{
    transform: translateY(0);
}

.white-wrap .header-scroll{
    height: v(100);
    background-color: #f5f5f5;
    box-shadow: 0 0 5px rgba(#000, .13);
}



@media (max-width: 1250px) {
    $fontScale: 1.3;

    .header{
        > .center > .time > p{
            font-size: v(24 * $fontScale);
        }
        > .right > .user .login-type{
            font-size: v(18 * $fontScale);
        }
    }
}

@media (max-width: 960px) {
    .header{
        height: v(155);

        > .logo{
            width: v(205);
        }
        .center{
            display: none;
        }
        .right{
            display: none;
        }

        > .phone-right{
            display: flex;
            align-items: center;

            span{
                font-size: v(40);
                color: #fff;
                margin-right: v(50);
            }
            .menu-icon{
                color: #19a764;
                font-size: v(90);
                cursor: pointer;
            }
            .home-icon{
                color: #fff;
                font-size: v(70);
                margin-right: v(50);
                cursor: pointer;
            }
        }
    }
    .white-wrap .header{
        > .phone-right{
            span, .home-icon{
                color: #19a764;
            }
        }
    }
    .header-scroll{
        height: v(155);
        background-color: #054031;
    }

    .white-wrap .header-scroll{
        height: v(155);
        background-color: #f5f6f6;
        box-shadow: none;
    }
}

@media (max-width: 560px) {
    .header{
        padding: 0 v(140);
        height: v(310);

        > .logo{
            width: v(410);
        }
        > .phone-right{
            span{
                font-size: v(90);
                margin-top: v(5);
            }

            .menu-icon{
                color: #19a764;
                font-size: v(180);
                cursor: pointer;
            }
            .home-icon{
                font-size: v(120);
            }
        }
    }

    .header-scroll{
        height: v(155 * 2);
    }

    .white-wrap .header-scroll{
        height: v(155 * 2);
    }
}
