.footer{
    position: relative;
    z-index: 99;
    padding-left: v(104);
    height: v(80);
    margin-top: v(-80);
    user-select: none;
    ul{
        display: flex;
        align-items: center;

        li{
            margin-right: v(20);
            font-size: v(14);
        }
    }
    a{
        color: #fff;
        line-height: 1.5em;
        &:hover{
            color: #99ffcc;
            text-decoration: underline;
        }
    }
}

.white-wrap .footer{
    a{
        color: #666666;
        &:hover{
            color: #3d785f;
        }
    }
}
