.nav {
    position: fixed;
    left: 0;
    width: v(236);
    top: v(177);
    z-index: 99;
    user-select: none;



    .number{
        position: absolute;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 50%;
        color: #fff;
        font-size: v(16);
        margin-top: v(-10);
        display: none;

        > span{
            width: v(30);
            height: 1px;
            background-color: #09a557;
            display: block;
            margin-right: 10px;
        }
    }
    .nav-main{
        display: block;
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
        @include tsn;
    }
    .live-btns{
        .live-btn{
            display: flex;
            justify-content: flex-end;
            width: 100%;

            .live-link {
                width: v(135);
                padding: v(12) 0;
                background-color: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: v(20);
                color: #003C1F;
                display: block;
                text-align: center;
                line-height: 1em;
                background-image: linear-gradient(45deg, #78cea4 0%, #d4fee9 100%);
                padding-left: 11px;
                > span{
                    line-height: 1em;
                }
                >.dian {
                    display: inline-block;
                    width: 8px;
                    height: 8px;
                    background-color: #cc0000;
                    border-radius: 50%;
                    vertical-align: top;
                    margin-left: 3px;

                }
            }
            span.live-link{
                user-select: none;
                cursor: default;
                background-color: #aaa;
                background-image: none;
                color: #fff;
            }
        }

        .local-btn{
            margin-top: v(15);

            > .live-link{
                background-image: none;
                background-color: #0ea15d;
                color: #fff;
                padding-left: 0;
            }
        }

    }


    ul {
        margin-top: v(100);
        width: 100%;
    }

    li {
        width: 100%;
        text-align: right;
        position: relative;

        .num{
            position: absolute;
            top: 0;
            left: 103%;
            font-size: v(13);
            color: #00A657;
            display: none;
        }
    }
    .link {
        font-size: v(24);
        color: #e8e8e8;
        display: inline-block;
        margin-bottom: v(25);
        border-bottom: v(5) solid transparent;
        white-space: nowrap;
        @include tsn(.15s);

        &:hover {
            color: #02a557;
        }
    }

    .link.active {
        font-size: v(48);
        border-bottom: v(5) solid #02a557;
        font-weight: bold;

        &:hover {
            color: #fff;
        }

        .num{
            display: block;
        }
    }
}


.white-wrap .nav{
    .link{
        color: #000;
    }

    .link.active {
        color: #00a657;
        &:hover {
            color: #00a657;
        }
    }
}

.nav-hidden{
    height: v(1080 - 340);

    > .number{
        display: flex;
    }
    .nav-main{
        opacity: 0;
        visibility: hidden;
        transform: translateX(-20px);
    }
    &:hover{
        > .number{
            display: none;
        }
        .nav-main{
            opacity: 1;
            visibility: visible;
            transform: translateX(0);
        }
    }

}

.nav-phone{
    display: none;
}

.wx-share{

    @include absoluteCenter;
    position: fixed;
    z-index: 999;
    width: v(1000);
    height: v(1000);
    background-color: #fff;

    i{
        position: absolute;
        top: v(-50);
        right: v(-50);
        color: #19a764;
        width: v(160);
        height: v(160);
        background-color: #19a764;
        color: #fff;
        font-size: v(90);
        text-align: center;
        line-height: v(160);
    }
    > div{
        width: 100%;
        height: 100%;
        padding: v(20);
    }
    img{
        width: 100%;
        height: 100%;
        display: block;
    }
}


@media (max-width: 1250px) {
    $fontScale: 1.3;

    .nav .link{
        font-size: (24 * $fontScale);
    }
}

@media (max-width: 960px) {
    .nav{
        display: none;
    }
    .footer{
        display: none;
    }
    $scale: 1;
    .nav-phone{
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        background-color: #054031;
        width: 100%;
        height: 100%;
        z-index: 98;
        padding: 0 7%;
        padding-top: v(100 * $scale);
        opacity: 0;
        transform: translateY(-100%);
        @include tsn;
        a{
            color: #fff;
        }

        .navs{

            margin: 0 auto;
            padding-top: v(100 * $scale);
            li{
                height: v(180 * $scale);
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #055f3b;

                .link{
                    display: inline-block;
                    position: relative;
                    font-size: v(60 * $scale);
                    .num{
                        position: absolute;
                        top: v(-8 * $scale);
                        right: v(-50 * $scale);
                        font-size: v(25 * $scale);
                        display: none;
                    }
                }
                .active{
                    color: #0bc365;
                    font-size: v(80 * $scale);
                    font-weight: bold;
                    .num{
                        display: block;
                    }
                }
            }
        }
        .login-type{
            margin-top: v(40 * $scale);
            .line1{
                display: flex;
                color: #17a663;
                justify-content: center;
                align-items: center;
                font-size: v(45 * $scale);

                .icon{
                    width: v(70 * $scale);
                    height: v(70 * $scale);
                    border: 1px solid #037c47;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-right: v(20 * $scale);
                    > i{
                        font-size: v(55 * $scale);
                        color: #037c47;
                    }
                }
            }
            .theme-btn{
                width: 100%;
                margin-top: v(54 * $scale);
            }
        }
        .not-login-type{
            margin-top: v(40 * $scale);
            .btns{
                display: flex;
                align-items: center;
                justify-content: space-between;

                a{
                    width: 49%;
                }
                .theme-btn{
                    width: 100%;
                }
            }
        }
        .footer-phone{
            width: 100%;
            bottom: 0;
            left: 0;
            position: absolute;
            padding: v(90 * $scale) 7%;

            .share{
                display: flex;
                align-items: center;
                color: #047141;
                margin-bottom: v(60 * $scale);
                a{
                    color: #047141;
                }
                .name{
                    font-size: v(30);
                    white-space: nowrap;
                    margin-right: v(20);
                }

                ul{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;

                    li{
                        text-align: center;
                        width: 25%;
                        border-right: 1px solid #047142;
                    }
                    li:last-child{
                        border-right: none;
                    }
                    i{
                        font-size: v(40);
                    }
                }
            }
            .footer-links{
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: v(28);
                a{
                    color: #999999;
                }
            }
        }
    }
    .nav-phone-show{
        opacity: 1;
        transform: translate(0);
    }

    .white-wrap .nav-phone{
        background-color: #f5f6f6;
        a{
            color: #696969;
        }
        .navs li{
            border-color: #d8d8d8;
        }
    }

}

@media (max-width: 560px) {

    $scale: 2;
    .nav-phone{
        padding-top: v(100 * $scale);

        .navs{
            padding-top: v(100 * $scale);
            li{
                height: v(150 * $scale);

                .link{
                    font-size: v(60 * $scale);

                    .num{
                        top: v(-8 * $scale);
                        right: v(-50 * $scale);
                        font-size: v(25 * $scale);
                    }
                }

                .active{
                    font-size: v(80 * $scale);
                }
            }
        }
        .login-type{
            .line1{
                margin-top: v(40 * $scale);
                font-size: v(45 * $scale);

                .icon{
                    width: v(70 * $scale);
                    height: v(70 * $scale);
                    margin-right: v(20 * $scale);

                    > i{
                        font-size: v(55 * $scale);
                    }
                }
            }
            .theme-btn{
                margin-top: v(54 * $scale);
            }
        }
        .not-login-type{
            margin-top: v(40 * $scale);
        }
        .footer-phone{
            padding: v(90 * $scale) 7%;

            .share{
                margin-bottom: v(60 * $scale);

                .name{
                    font-size: v(26* $scale);
                    margin-right: v(20* $scale);
                }
                ul{
                    i{
                        font-size: v(40* $scale);
                    }
                }
            }
            .footer-links{
                font-size: v(28* $scale);

            }
        }
    }
}
