.slide-left-enter-active {
    transition: all .3s ease-in;
}
.slide-left-leave-active {
    transition: all .3s ease-out;
}
.slide-left-leave{
    transform: translateX(0);
    opacity: 1;
}
.slide-left-leave-to{
    transform: translateX(100px);
    opacity: 0;
}
.slide-left-enter{
    transform: translateX(-20px);
    opacity: 0;
}
.slide-left-enter-to{
    transform: translateX(0px);
    opacity: 1;
}

.slide-right-enter-active {
    transition: all .3s ease-in;
}
.slide-right-leave-active {
    transition: all .3s ease-out;
}
.slide-right-leave{
    transform: translateX(0);
    opacity: 1;
}
.slide-right-leave-to{
    transform: translateX(-100px);
    opacity: 0;
}
.slide-right-enter{
    transform: translateX(20px);
    opacity: 0;
}
.slide-right-enter-to{
    transform: translateX(0px);
    opacity: 1;
}





@keyframes suspension1 {
    0% {
        opacity: 0;
        transform: translate3d(0, 0, 0) rotateZ(3deg);
    }
    20%{
        opacity: 1;
        transform: translate3d(50px, 20px, 0) rotateZ(5deg);
    }
    40%{
        transform: translate3d(20px, 90px, 0) rotateZ(8deg);
    }
    60%{
        transform: translate3d(30px, 50px, 0) rotateZ(-10deg);
    }
    80%{
        transform: translate3d(30px, 15px, 0) rotateZ(-5deg);
    }
    100% {
        transform: translate3d(0, 0, 0) rotateZ(-3deg);
    }
}
@keyframes suspension2 {
    0% {
        opacity: 0;
        transform: translate3d(0, 0, 0) rotateZ(-5deg);
    }
    20%{
        opacity: 1;
        transform: translate3d(20px, 10px, 0) rotateZ(9deg);
    }
    40%{
        transform: translate3d(-10px, -20px, 0) rotateZ(3deg);
    }
    60%{
        transform: translate3d(20px, 10px, 0) rotateZ(-7deg);
    }
    80%{
        transform: translate3d(0px, 15px, 0) rotateZ(2deg);
    }
    100% {
        transform: translate3d(0, 0, 0) rotateZ(3deg);
    }
}
@keyframes suspension3 {
    0% {
        opacity: 0;
        transform: translate3d(0, 0, 0) rotateZ(-3deg);
    }
    20%{
        opacity: 1;
        transform: translate3d(-20px, -20px, 0) rotateZ(5deg);
    }
    40%{
        transform: translate3d(0px, 10px, 0) rotateZ(13deg);
    }
    60%{
        transform: translate3d(50px, 15px, 0) rotateZ(5deg);
    }
    80%{
        transform: translate3d(20px, -15px, 0) rotateZ(-8deg);
    }
    100% {
        transform: translate3d(0, 0, 0) rotateZ(3deg);
    }
}

@keyframes breathing {
    0%{
        opacity: .3;
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: .3;
    }
}


@keyframes p4rotate1 {
    0%{
        transform: perspective(v(1300)) translate3d(0px, 0px, v(0)) rotateX(0deg) rotateY(0deg);
        opacity: 1;
    }
    100%{
        opacity: 1;
        transform: perspective(v(1300)) translate3d(0px, 0px, v(0)) rotateX(0deg) rotateY(-65deg);
    }
}
@keyframes p4rotate2 {
    0%{
        transform: perspective(v(1300)) translate3d(0px, 0px, v(0)) rotateX(0deg) rotateY(0deg);
        opacity: 1;
    }
    100%{
        opacity: .5;
        transform: perspective(v(1300)) translate3d(v(-150), v(-30), v(-50)) rotateX(0deg) rotateY(-65deg) scale(.9);
    }
}
@keyframes p4rotate3 {
    0%{
        transform: perspective(v(1300)) translate3d(0px, 0px, v(0)) rotateX(0deg) rotateY(0deg);
        opacity: 1;
    }
    100%{
        opacity: .5;
        transform: perspective(v(1300)) translate3d(v(150), 0px, v(0)) rotateX(0deg) rotateY(-65deg) scale(.95);
    }
}


@keyframes debris {
    0%{
        transform: scale(1)
    }
    50%{
        transform: scale(.91)
    }
    100%{
        transform: scale(1)
    }
}
