.live-content{
    padding-left: v(377);
    padding-right: v(277);
    padding-top: v(220);
}
.live-block{
    width: v(1266);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: v(20);

    > .left{
        width: v(949);


        .video-area{
            width: 100%;
            height: v(533.8125);
            margin-bottom: v(8);
        }
        .lives-area{
            width: 100%;
            height: v(207);
            background-color: #023526;
            overflow: hidden;
            display: flex;
            justify-content: space-between;

            > .item{
                width: v(185);
                height: 100%;
                color: #fff;
                cursor: pointer;
                @include tsn;
                position: relative;
                // border: 2px solid transparent;

                &:after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 2px solid #98bdbb;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    box-sizing: border-box;
                    opacity: 0;
                    @include tsn;
                }


                &:hover{
                    &:after{
                        opacity: 1;
                    }
                }

                > .cover{
                    width: 100%;
                    background-size: cover;
                    background-position: center center;
                    height: v(147);
                    background-color: #09543c;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .play-icon{

                        width: v(40);
                        height: v(40);
                        border-radius: 50%;
                        color: #09a557;
                        border: 2px solid #09a557;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        display: none;
                        .iconfont{
                            font-size: v(28);
                        }
                    }
                    > .title{
                        position: absolute;
                        bottom: 50%;
                        font-weight: bold;
                        left: 0;
                        width: 100%;
                        color: #fff;
                        text-align: center;
                        font-size: v(22);
                        padding: 0 v(30);
                        transform: translateY(50%);
                    }
                }

                > .lines{
                    height: v(60);
                    display: flex;
                    padding: v(9) v(12);
                    line-height: 1.35em;
                    font-size: v(14);

                }
            }
            .item:nth-child(1) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110259953.jpg);
                }
                > .lines{
                    background-color: #1d4c66;
                }
            }
            .item:nth-child(2) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110244579.jpg);

                }
                > .lines{
                    background-color: #766437;
                }
            }
            .item:nth-child(3) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110264029.jpg);

                }
                > .lines{
                    background-color: #216b4b;
                }
            }
            .item:nth-child(4) {

                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110288057.jpg);

                }
                > .lines{
                    background-color: #435476;
                }
            }
            .item:nth-child(5) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110227670.jpg);

                }
                > .lines{
                    background-color: #7c474d;
                }
            }

            > .active.item{
                // border: 2px solid #98bdbb;
                &:before{
                    display: none;
                }
                &:after{
                    opacity: 1;
                }
            }

            > .disable.item{
                cursor: default;

                // > .cover{
                //     background-image: url(https://img.mofyi.com/Ayto/image/20210916154737.jpg);
                // }
                // > .lines{
                //     background-color: #696969;
                // }

                &:hover{
                    &:after{
                        opacity: 0;
                    }
                }

                &:after{
                    opacity: 0;
                }
                &:before{
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    background-color: rgba(#000, .5);
                    width: 100%;
                    height: 100%;
                    z-index: 5;
                    box-sizing: border-box;
                }
            }

        }

        .lives-area2{
            > .item{
                width: v(232);
            }
        }
    }
    > .right{
        width: v(309);

        .ppt-area{
            height: v(165);
            margin-bottom: v(8);
            display: flex;
            align-items: center;
            justify-content: center;

        }
        .interactive-aera{
            position: relative;
            height: v(576);
            border: 1px solid #197b4c;
            .el-scrollbar{
                width: 100%;
                height: v(450);
                overflow-x: hidden;
                .el-scrollbar__wrap{
                    overflow-x: hidden;
                }
            }
            .el-scrollbar__bar.is-vertical{
                background-color: rgba(#05914f, .3);
                border-radius: 0;
            }
            .el-scrollbar__bar.is-vertical > div{
                background-color: rgba(#05914f, 1);
                border-radius: 0;
            }

            .comments{
                padding: v(25);

                > div{
                    margin-bottom: v(10);
                }
                .name{
                    color: #9ea2a1;
                    font-size: v(15);
                    display: inline-block;
                    margin-right: v(10);
                }
                .host-name{
                    color: #00a657;
                }
                .text{
                    font-size: v(15);
                    color: #fff;
                }
            }

            textarea{
                border: none;
                outline: none;
                background-color: #d5ffea;
                resize: none;
                width: 100%;
                height: v(70);
                padding: v(10);
                color: #666;
                display: block;
            }

            .operation-line{
                height: v(55);
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 v(10);

                > .icon{
                    color: #09a557;
                    margin-right: v(30);
                    cursor: pointer;
                    .iconfont{
                        font-size: v(30);
                    }
                }

                button{
                    width: v(120);
                    height: v(40);
                }

            }
        }
    }

    > .draw-btn{
        width: v(200);
        height: v(205);
        background-image: url(https://img.mofyi.com/Ayto/image/20210907095649.png);
        background-size: v(200 * 14) v(205);
        background-position: 0 0;
        position: absolute;
        bottom: 0;
        left: 102%;
        cursor: pointer;
        z-index: 101;
        animation: breathing 3.5s infinite linear;

        &:hover{
            animation: none;
        }
    }
    @for $i from 0 to 20{
        .draw#{$i}{
            background-position: v(-200 * $i) 0;
        }
    }
    > .disable.draw-btn{
        background-position: center center;
        background-size: 100% 100%;
        background-image: url(https://img.mofyi.com/Ayto/image/20210913162047.png);
        animation: none;
        cursor: default;
    }

    > .live-video, > .live-ppt{
        position: absolute;
        z-index: 5;
    }
    > .live-video{
        #dplayer{
            width: 100%;
            height: 100%;
            background: #000;
        }
        video{
            display: block;
        }
        > .video-end-cover{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 100;
            width: 100%;
            height: 100%;
            display: block;
            background-size: 100% 100%;
            background-image: url(https://img.mofyi.com/Ayto/image/20210923121509.jpg);
        }
    }
    > .live-ppt{
        background-color: #000;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    > .live-main-window{
        top: 0;
        left: 0;
        width: v(949);
        height: v(534);
    }
    > .live-deputy-window{
        top: 0;
        right: 0;
        width: v(309);
        height: v(165);
    }
    .change-btn{
        position: absolute;
        z-index: 9;
        right: v(10);
        top: v(128);
        cursor: pointer;
        background-color: rgba(#fff, 0);
        padding: v(3) v(8);
        border-radius: v(3);
        @include tsn;

        &:hover{
            background-color: rgba(#fff, .6);
        }
        img{
            width: v(24);
            display: block;
        }
    }
    .emoji-list{
        display: none;
        position: absolute;
        left: 0;
        width: 100%;
        height: auto;
        bottom: v(205);
        z-index: 99;
        @include tsn;
        @include tsm(translateY(100%));

        ul{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            background-color: #fff;
            padding: v(10);
            overflow-y: auto;
            @include boxSizing;
            width: 100%;
            height: auto;
            bottom: v(0);
            border-top: 1px solid #d7d7d7;
        }
        li{
            font-size: v(30);
            width: v(35);
            height: v(33);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
    .emoji-list-show{
        display: block;
    }
}

.local-block{
    justify-content: center;
    > .left{
        // width: 100%;

        .video-area{
            width: 100%;
            padding-bottom: 56.25%;
            position: relative;

            .live-video{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                .dplayer{
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .lives-area{
            width: 100%;
            height: v(209);
            background-color: #023526;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            z-index: 10;

            > .item{
                width: v(185);
                height: v(100);
                color: #fff;
                cursor: pointer;
                @include tsn;
                position: relative;

                &:after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 2px solid #98bdbb;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    box-sizing: border-box;
                    opacity: 0;
                    @include tsn;
                }



                &:hover{
                    &:after{
                        opacity: 1;
                    }
                }

                > .cover{
                    width: 100%;
                    background-size: cover;
                    background-position: center center;
                    height: 100%;
                    background-color: #09543c;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > .title{
                        position: absolute;
                        bottom: 50%;
                        font-weight: bold;
                        left: 0;
                        width: 100%;
                        color: #fff;
                        text-align: center;
                        font-size: v(22);
                        padding: 0 v(30);
                        transform: translateY(50%);
                    }
                }
            }
            .item:nth-child(1), .item:nth-child(9) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110259953.jpg);
                }
            }
            .item:nth-child(2), .item:nth-child(8) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110244579.jpg);
                }
            }

            .item:nth-child(3), .item:nth-child(7) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110264029.jpg);
                }
            }
            .item:nth-child(4), .item:nth-child(6) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110227670.jpg);
                }
            }
            .item:nth-child(5){
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110288057.jpg);
                }
            }

            .item:nth-child(10) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110227670.jpg);

                }
            }
            > .active.item{
                &:after{
                    opacity: 1;
                }
            }
            > .none-item.item{
                cursor: default;
                &:hover{
                    &:after{
                        opacity: 0;
                    }
                }
            }

        }
    }
}





@media (max-width: 1250px) {
    $fontScale: 1.5;

}

@media (max-width: 960px) {
    $headerHieght: 155;
    .live-content{
        padding-left: 0;
        padding-right: 0;
        position: relative;
    }

    .live-block-phone{
        .draw-btn{
            position: fixed;
            bottom: v(400);
            right: v(50);
            width: v(400);
            height: v(410);
            background-image: url(https://img.mofyi.com/Ayto/image/20210907095649.png);
            background-size: v(400 * 14) v(410);
            background-position: 0 0;
            cursor: pointer;
            z-index: 66;
        }
        @for $i from 0 to 20{
            .draw#{$i}{
                background-position: v(-400 * $i) 0;
            }
        }
        > .disable.draw-btn{
            background-position: center center;
            background-size: 100% 100%;
            background-image: url(https://img.mofyi.com/Ayto/image/20210913162047.png);
            animation: none;
            cursor: default;
        }
        .live-video{
            position: absolute;
            left: 0;
            top: v($headerHieght);
            z-index: 3;
            width: v(1920);
            height: v(1080);
            background-color: #000;

            .video-end-cover{
                position: absolute;
                top: 0;
                left: 0;
                z-index: 100;
                width: 100%;
                height: 100%;
                display: block;
                background-size: 100% 100%;
                background-image: url(https://img.mofyi.com/Ayto/image/20210923121509.jpg);
            }
        }
        .lives-area{
            position: absolute;
            z-index: 3;
            top: v($headerHieght + 1080);
            left: 0;
            width: 100%;
            height: v(250);
            background-color: #023526;
            overflow-x: auto;
            // display: flex;
            justify-content: space-between;

            > div{
                display: flex;
                width: 100%;
                height: 100%;
            }
            .item{
                width: 25%;
                margin-right: 0;
                height: 100%;
                background-color: #04402e;
                color: #fff;
                cursor: pointer;
                @include tsn;
                position: relative;

                &:after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 2px solid #98bdbb;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    box-sizing: border-box;
                    opacity: 0;
                    @include tsn;
                }

                > .cover{
                    background-size: cover;
                    background-position: center center;
                    height: 100%;
                    background-color: #09543c;
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .play-icon{
                        display: none;
                    }
                    > .title{
                        position: absolute;
                        bottom: 50%;
                        transform: translateY(50%);
                        left: 0;
                        width: 100%;
                        color: #fff;
                        padding-bottom: 0;
                        text-align: center;
                        font-size: v(50);
                        font-weight: bold;
                    }
                }

                > .lines{
                   display: none;
                }
            }
            .item:nth-child(1) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110259953.jpg);
                }
                > .lines{
                    background-color: #1d4c66;
                }
            }
            .item:nth-child(2) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110244579.jpg);

                }
                > .lines{
                    background-color: #766437;
                }
            }
            .item:nth-child(3) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110264029.jpg);

                }
                > .lines{
                    background-color: #216b4b;
                }
            }
            .item:nth-child(4) {

                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110288057.jpg);

                }
                > .lines{
                    background-color: #435476;
                }
            }
            .item:nth-child(5) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110227670.jpg);

                }
                > .lines{
                    background-color: #7c474d;
                }
            }
            .active.item{
                &:before{
                    display: none;
                }
                &:after{
                    opacity: 1;
                }
            }
            .disable.item{
                cursor: default;

                // > .cover{
                //     background-image: url(https://img.mofyi.com/Ayto/image/20210916154737.jpg);
                // }
                // > .lines{
                //     background-color: #696969;
                // }
                &:after{
                    opacity: 0;
                }

                &:before{
                    display: block;
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(#000, .5);
                    z-index: 5;
                    box-sizing: border-box;
                }
            }

        }
        .lives-area2 {

            > .item{
                width: 33.32222%;
            }
        }
        .live-message{
            position: absolute;
            left: 0;
            top: v($headerHieght + 1080 + 250);
            width: 100%;
            height: v(160);
            border-bottom: 1px solid #0b2f29;
            background-color: #1b3a3f;
        }
        .live-menu{
            position: absolute;
            left: 0;
            top: v($headerHieght + 1080 + 250 + 160);
            width: 100%;
            height: v(160);
            display: flex;
            border-bottom: 1px solid #0b2f29;

            > div{
                width: 50%;
                text-align: center;
                height: 100%;
                line-height: v(160);
                font-size: v(80);
                color: #9aa6a5;
                background-color: #03221d;
            }

            > .active{
                background-color: #052a24;
                color: #1bd57c;
            }

        }
        .live-nr{
            overflow-x: hidden;
            overflow-y: auto;
            position: absolute;
            width: 100%;
            left: 0;

            top: v($headerHieght + 1080 + 250 + 160 + 160);
            height: calc(100% - 1.2916666667rem - 9rem - 2.0833333333rem - 1.3333333333rem - 1.3333333333rem - 3.3333333333rem);

            > div{
                width: 100%;
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: 0;
            }

            .ppt-area{
                img{
                    width: 100%;
                    max-width: 100%;
                }
            }

            .interactive-aera{
                position: relative;

                .el-scrollbar{
                    width: 100%;
                    height: 100%;
                    overflow-x: hidden;
                    .el-scrollbar__wrap{
                        overflow-x: hidden;
                    }
                }
                .el-scrollbar__bar.is-vertical{
                    background-color: rgba(#05914f, .3);
                    border-radius: 0;
                }
                .el-scrollbar__bar.is-vertical > div{
                    background-color: rgba(#05914f, 1);
                    border-radius: 0;
                }

                .comments{
                    padding: v(45);

                    > div{
                        margin-bottom: v(30);
                    }
                    .name{
                        color: #9ea2a1;
                        font-size: v(55);
                        display: inline-block;
                        margin-right: v(20);
                    }
                    .host-name{
                        color: #00a657;
                    }
                    .text{
                        font-size: v(55);
                        color: #fff;
                    }
                }
            }
        }

        .live-bottom{
            background-color: #197458;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: v(400);
            display: flex;
            z-index: 15;

            textarea{
                width: v(640 * 2);
                height: 100%;
                background-color: transparent;
                border: none;
                outline: none;
                padding: v(80);
                color: #ffffff;
                resize: none;

                &::-webkit-input-placeholder{
                    color: #a6c2b9;
                }
            }
            .operation-line{
                position: relative;
                top: v(45);
                width: v(320 * 2);
                display: flex;
                align-items: center;
                justify-content: center;
                height: v(120);

                .icon{
                    color: #13e785;
                    i{
                        font-size: v(100);
                    }
                }
                span{
                    display: block;
                    height: 100%;
                    border-left: 1px solid #9fc6bb;
                    padding: 0 v(80);
                    margin-left: v(80);
                    font-size: v(100);
                    color: #fff;

                }
            }
        }
    }

    .local-block-phone{
        .local-message{
            position: absolute;
            top: v($headerHieght + 1080);
            left: 0;
            width: 100%;
            height: v(160);
            border-bottom: 1px solid #0b2f29;
            background-color: #1b3a3f;
        }
        .local-content{
            position: absolute;
            z-index: 3;
            top: v($headerHieght + 1080 + 160);
            left: 0;
            width: 100%;
            height: calc(100% - 9rem - 1.2916666667rem  - 1.3333333333rem);
            overflow: auto;
            background-color: #023526;
        }
        .lives-area-local{
            padding: v(80);
            background-color: #023526;
            overflow: hidden;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            > .item{
                width: 32%;
                padding-bottom: 32 * 0.5405405405405406%;
                color: #fff;
                cursor: pointer;
                margin-bottom: 2%;
                @include tsn;
                position: relative;
                // border: 2px solid transparent;

                &:after{
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    border: 2px solid #98bdbb;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                    box-sizing: border-box;
                    opacity: 0;
                    @include tsn;
                }

                &:hover{
                    &:after{
                        opacity: 1;
                    }
                }

                > .cover{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    background-size: cover;
                    background-position: center center;
                    height: 100%;
                    background-color: #09543c;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    > .title{
                        position: absolute;
                        bottom: 50%;
                        font-weight: bold;
                        left: 0;
                        width: 100%;
                        color: #fff;
                        text-align: center;
                        font-size: v(22);
                        padding: 0 v(30);
                        transform: translateY(50%);
                    }
                }
            }
            .item:nth-child(1), .item:nth-child(9) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110259953.jpg);
                }
            }
            .item:nth-child(2), .item:nth-child(8) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110244579.jpg);
                }
            }

            .item:nth-child(3), .item:nth-child(7) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110264029.jpg);
                }
            }
            .item:nth-child(4), .item:nth-child(6) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110227670.jpg);
                }
            }
            .item:nth-child(5) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110288057.jpg);
                }
            }

            .item:nth-child(10) {
                > .cover{
                    background-image: url(https://img.mofyi.com/202108/2616110227670.jpg);

                }
            }
            > .active.item{
                &:after{
                    opacity: 1;
                }
            }
            > .none-item.item{
                cursor: default;
                &:hover{
                    &:after{
                        opacity: 0;
                    }
                }
            }

        }
    }

    .emoji-list{
        display: none;
        position: absolute;
        z-index: 1;
        left: 0;
        width: 100%;
        height: auto;
        bottom: v(400);
        z-index: 99;
        @include tsn;

        ul{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            background-color: #fff;
            padding: v(10);
            overflow-y: auto;
            @include boxSizing;
            width: 100%;
            height: auto;
            bottom: v(0);
            border-top: 1px solid #d7d7d7;
        }
        li{
            font-size: v(120);
            width: v(200);
            height: v(200);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
    }
    .emoji-list-show{
        display: block;
    }
}

@media (max-width: 560px) {
    $headerHieght: 310;


    .live-block-phone{
        .live-video{
            top: v($headerHieght);
        }
        .lives-area{
            top: v($headerHieght + 1080);
        }
        .live-message{
            top: v($headerHieght + 1080 + 250);
        }
        .live-menu{
            top: v($headerHieght + 1080 + 250 + 160);
        }
        .live-nr{
            top: v($headerHieght + 1080 + 250 + 160 + 160);
            height: calc(100% - 1.2916666667rem - 9rem - 2.0833333333rem - 2.6333333333rem - 1.3333333333rem - 3.3333333333rem);
        }
    }

    .local-block-phone{
        .local-message{
            position: absolute;
            top: v($headerHieght + 1080);
            left: 0;
            width: 100%;
            height: v(160);
            border-bottom: 1px solid #0b2f29;
            background-color: #1b3a3f;
        }
        .local-content{
            position: absolute;
            z-index: 3;
            top: v($headerHieght + 1080 + 160);
            left: 0;
            width: 100%;
            height: calc(100% - 9rem - 2.5833333333rem  - 1.3333333333rem);
            overflow: auto;
            background-color: #023526;
        }

        .lives-area-local > .item > .cover > .title{
            font-size: v(65);
        }
    }
}
