.login-block{
    width: 100%;
    padding: v(70) v(140);

    .title{
        h3{
            font-size: v(45);
            color: #fff;
        }
        h4{
            font-size: v(34);
            color: #fff;
            font-weight: normal;

            > span{
                font-size: 120%;
            }
        }
    }

    .login-form{
        max-width: v(400);
        margin: v(100) auto 0;

        > .text{
            color: #fff;
            margin-bottom: v(50);
        }
        .email-input{
            margin-bottom: v(10);
        }
        .password-input{
            margin-bottom: v(18);
        }
        .wasureru{
            text-align: right;
            font-size: 14px;
            margin-bottom: v(45);
            a{
                color: #fff;
                display: inline-block;
                padding-bottom: 3px;
                border-bottom: 1px solid #146e43;

                i{
                    font-size: 16px;
                    vertical-align: text-top;

                }
            }
        }
        .btn-line{
            .theme-btn{
                font-size: 16px;
            }
            span{
                font-size: 16px;
                margin-left: 10px;
                color: #fff;
                a{
                    color: #1AA763;
                    display: inline-block;
                    padding-bottom: 3px;
                    border-bottom: 1px solid #6abe28;
                }
            }
        }
    }
}



@media (max-width: 1250px) {
    $fontScale: 1.3;

    .login-block .login-form{
        max-width: none;
    }
}

@media (max-width: 960px) {
    $scale: 1;

    .login-block{
        padding-top: v(240);

        .title{
            text-align: center;
            h3{
                font-size: v(116);
                color: #fff;
            }
            h4{
                font-size: v(87);
                color: #fff;
                font-weight: normal;

                > span{
                    font-size: 120%;
                }
            }
        }

        .login-form {
            .email-input{
                margin-bottom: v(35);
            }
            .btn-line {
                text-align: center;
                .theme-btn{
                    width: 100%;
                    height: v(115);
                    margin-bottom: v(30);
                }
            }
        }
    }
}

@media (max-width: 560px) {
    $scale: 2;

    .login-block{
        padding-top: v(240 * $scale);

        .title{
            h3{
                font-size: v(116 * $scale);
            }
            h4{
                font-size: v(87 * $scale);
            }
        }

        .login-form {
            .email-input{
                margin-bottom: v(35 * $scale);
            }
            .btn-line {
                .theme-btn{
                    height: v(115 * $scale);
                    margin-bottom: v(30 * $scale);
                }
            }
        }
    }
}
