$pro: 320/375/20;

@function r($num){
    @return $num * $pro * 1rem;
}

@function v($num){
    @return $num * 320/1920/20 * 1rem;
    // @return $num / 1920 * 100vw;
    // @return $num * 1px;
}

@mixin borderR($deg:0) {
    border-radius: $deg;
    -webkit-border-radius: $deg;
    -moz-border-radius: $deg;
}

@mixin boxSizing {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

@mixin tsn($time:.3s) {
    transition: ($time);
    -webkit-transition: ($time);
    -moz-transition: ($time);
}

@mixin tsm($content) {
    transform: $content;
    -webkit-transform: $content;
    -moz-transform: $content;
}

@mixin font($size, $color) {
    font-size: $size;
    color: $color;
}

@mixin boxShadow($content) {
    box-shadow: $content;
    -webkit-box-shadow: $content;
    -moz-box-shadow: $content;
    -o-box-shadow: $content;
    -ms-box-shadow: $content;
}

//百分之百的遮罩层，参数为index
@mixin coverLayer($zIndex) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $zIndex;
}

//文字省略半透明层，参数为height
@mixin txtMask($height) {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: $height;
    bottom: 0;
    left: 0;
    background: linear-gradient(to top, #fff 35%, rgba(255, 255, 255, 0));
    background: -webkit-linear-gradient(to top, #fff 35%, rgba(255, 255, 255, 0));
}

/* 绝对居中 */
@mixin absoluteCenter() {
    position: absolute;
    top: 50%;
    left: 50%;
    @include tsm(translate(-50%, -50%));
}

@mixin boxCenter($width: 1170px){
    width: $width;
    margin: 0 auto;

    @if($width == 1170px){
        @media (max-width: 1200px){
            width: 90%;
        }
    }
}

/* 单行溢出省略 */
@mixin textOver() {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/*怪异盒模型*/
@mixin boxSizing() {
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

/*禁止选中文本*/
@mixin userSelect() {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* 渐变 */
@mixin linearGradient($deg,$color1,$color2){
    background: -webkit-linear-gradient($deg,$color1,$color2); /* Safari 5.1 - 6.0 */
    background: -o-linear-gradient($deg,$color1,$color2); /* Opera 11.1 - 12.0 */
    background: -moz-linear-gradient($deg,$color1,$color2); /* Firefox 3.6 - 15 */
    background: linear-gradient($deg,$color1,$color2); /* 标准的语法 */
}
/*淡出*/
@mixin fade-show() {
    visibility: visible;
    opacity: 1;
}

/*淡出*/
@mixin fade-hide() {
    visibility: hidden;
    opacity: 0;
}

/*淡人*/
@mixin fade-show-big() {
    visibility: visible;
    opacity: 1;
    @include tsm(scale(1));
}

/*淡出*/
@mixin fade-hide-big() {
    visibility: hidden;
    opacity: 0;
    @include tsm(scale(1.2));
}

@mixin animation($content) {
    animation: $content;
    -webkit-animation: $content;
    -moz-animation: $content;
}

@mixin test(){
    min-height: 60px;
    outline: 1px solid #88741c;
}
