body, h1, h2, h3, h4, h5, h6, hr, p, blockquote, dl, dt, dd, ul, ol, li, pre, form, fieldset, legend, button, input, textarea, th, td {
    margin: 0;
    padding: 0;
}
*{
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
}

address, cite, dfn, em, var {
    font-style: normal;
}

code, kbd, pre, samp {
    font-family: couriernew, courier, monospace;
}

small {
    font-size: 12px;
}

ul, ol {
    list-style: none;
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

sup {
    vertical-align: text-top;
}

sub {
    vertical-align: text-bottom;
}

legend {
    color: #000;
}

fieldset, img {
    border: 0;
}

input, select, textarea {
    font-size: 100%;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

.clear:after {
    content: "";
    display: block;
    clear: both;
}
.float-tag:after {
    content: "";
    display: block;
    clear: both;
}
.clear, .float-tag {
    zoom: 1;
}
