//主题色
$themeColor: #02904e;

//选中主题色
$activeColor: #018042;

//警告色

//边框色

//背景色
