.agenda-content{
    padding-left: v(354);
    padding-right: v(279);
}
.agenda-block{
    position: relative;
    > .iconfont{
        position: absolute;
        top: 50%;
        z-index: 10;
        color: rgba(#129967, .5);
        font-size: v(100);
        margin-top: v(-120);
        cursor: pointer;
        @include tsn;
        &:hover{
            color: #129967;
        }
    }
    .icon-left{
        left: v(-50);
    }
    .icon-right{
        right: v(-50);
    }
}
.agenda-block-phone{
    display: none;
}
.agenda-swiper{
    height: v(760);
    margin-top: v(70);

    // &::after{
    //     content: '';
    //     position: absolute;
    //     height: 100%;
    //     width: v(300);
    //     left: 0;
    //     top: 0;
    //     z-index: 2;
    //     @include test;
    //     @include linearGradient(90deg, rgba(#112c37, .5) 0%, rgba(#112c37, 0) 100%)

    // }
    .swiper-slide{
        padding: 0 v(60);
        text-align-last: left;

        color: #fff;

        .time1{
            font-size: v(16);
            padding-top: v(15);
        }
        .time2{
            font-size: v(48);
            font-weight: 500;
            margin-bottom: v(10);
        }
        .name{
            color: #00ffbe;
            font-size: v(30);
            height: v(100);
            border-bottom: 1px solid #1a734d;
        }
        .type{

            margin-top: v(25);
            font-size: v(16);
            margin-bottom: v(50);
        }
        .speaker-items{
            li{
                margin-bottom: v(15);
                display: flex;
                justify-content: space-between;
                .key{
                    font-size: v(18);
                    color: #00FFAE;
                    width: 32%;
                    cursor: pointer;
                    a{
                        color: #00FFAE;
                    }

                    &:hover{
                        color: #fff;
                    }
                }
                .val{
                    width: 66%;
                    font-size: v(16);
                }
            }
        }
        .breakouts-items{
            width: 100%;
            font-size: v(18);
            color: #00FFAE;
            padding-top: v(30);
            li{
                padding: v(10);
                border-bottom: 1px solid #136642;
                margin-bottom: v(15);
                cursor: pointer;


                .key{
                    display: inline-block;
                    &:hover{
                        color: #fff;
                    }
                }

            }
        }
    }
    .swiper-slide-next{
        border-left: 1px solid #1a734d;
        border-right: 1px solid #1a734d;
    }
}

.agenda-popover{
    color: #fff;
    .title{
        font-size: 14px;
        color: #fff;
        margin-bottom: 8px;
    }
    .title1{
        font-size: 13px;
        color: #97b7ab;
        margin-bottom: 5px;
        margin-top: -3px;
    }
    .text{
        color: #00FFAE;
        font-size: 14px;
    }
    .white-text{
        color: #fff;
        font-size: 20px;
    }
    .speaker-items{
        margin-bottom: 15px;
        > p{
            text-align: left;
            margin-bottom: 5px;
            a{
                color: #00FFAE;
            }
            > span:last-child{
                color: #e9e9e9;
                font-size: 13px;
            }
        }
        > p:last-child{
            margin-bottom: 0;
        }
    }

}
.agenda-popper-parent{
    padding: 0;
}
.agenda-popover2{
    .cover{
        width: 100%;
        padding-bottom: 123.6842105263158%;
        background-size: cover;
        background-position: center center;
    }
    .name{
        text-align: center;
        background-color: #09a557;
        color: #fff;
        padding: 5px 0;
    }
}



@media (max-width: 1250px) {
    $fontScale: 1.5;

}

@media (max-width: 960px) {
    .agenda-block{
        display: none;
    }
    .agenda-content{
        padding-left: 0;
        padding-right: 0;
        width: 100%;
        margin: 0 auto;
    }
    .agenda-block-phone{
        display: block;

        > .title{
            color: #fff;
            width: 86%;
            margin: 0 auto;
            margin-bottom: v(30);
            font-size: v(46);
        }
        .agenda-items{
            color: #fff;


            .agenda-item{
                width: 100%;
                margin: 0 auto;
                padding: v(60) 7%;
                border-top: 1px solid #1a734d;
            }


            .time2{
                font-size: v(105);
                font-weight: 500;
                margin-bottom: v(10);
            }
            .name{
                font-size: v(54);
                color: #00ffbe;
            }
            .type{

                margin-top: v(30);
                font-size: v(30);
                margin-bottom: v(30);
            }
            .speaker-items{
                li{
                    margin-bottom: v(15);
                    display: flex;
                    justify-content: space-between;
                    .key{
                        font-size: v(30);
                        color: #00FFAE;
                        width: 20%;
                        cursor: pointer;

                        a{
                            color: #00FFAE;
                        }

                    }
                    .val{
                        width: 78%;
                        font-size: v(30);
                    }
                }
            }

            .el-collapse{
                border: none;
            }
            .el-collapse-item{
                margin-bottom: 20px;
            }
            .el-collapse-item__header{
                background-color: #43876c;
                border: none;
                color: #fff;
                position: relative;

                &.is-active{
                    background-color: #1ba663;
                }

                .title{
                    width: 100%;
                    padding: 0 20px;
                    padding-right: 40px;
                    line-height: 1.2em;


                    .header-icon{
                        position: absolute;
                        right: 20px;
                        top: 17px;
                    }
                }
            }
            .el-collapse-item__wrap{
                background-color: transparent;
                color: #fff;
                border: none;
            }
            .el-collapse-item__content{
                color: #fff;
                padding: 20px;
                padding-bottom: 0px;
            }
            .el-collapse-item__arrow{
                display: none;
            }
        }
    }
}

@media (max-width: 560px) {
    $scale: 2;


    .agenda-block-phone{

        > .title{
            margin-bottom: v(30 * $scale);
            font-size: v(46 * $scale);
        }
        .agenda-items{

            .agenda-item{
                padding: v(60 * $scale) 7%;
            }


            .time2{
                font-size: v(80 * $scale);
                font-weight: 500;
                margin-bottom: v(10 * $scale);
            }
            .name{
                font-size: v(44 * $scale);
            }
            .type{

                margin-top: v(30 * $scale);
                font-size: v(30 * $scale);
                margin-bottom: v(30 * $scale);
            }
            .speaker-items{
                li{
                    margin-bottom: v(15 * $scale);
                    .key{
                        font-size: v(30 * $scale);
                    }
                    .val{
                        font-size: v(30 * $scale);
                    }
                }
            }
        }
    }
    .agenda-popover{
        .title{
            font-size: 13px;
            margin-bottom: 5px;
        }
        .title1{
            font-size: 12px;
            color: #97b7ab;
            margin-bottom: 3px;
            margin-top: -1px;
        }
        .text{
            color: #00FFAE;
            font-size: 13px;
        }
        .white-text{
            color: #fff;
            font-size: 16px;
        }
        .speaker-items{
            margin-bottom: 8px;
            > p{
                text-align: left;
                margin-bottom: 3px;

                > span:last-child{
                    color: #e9e9e9;
                    font-size: 12px;
                }
            }
            > p:last-child{
                margin-bottom: 0;
            }
        }

    }
}
