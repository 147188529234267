.report-content{
    padding-left: v(307);
    padding-right: v(505);
}
.report-block{
    width: v(1108);
    margin: 0 auto;
    padding-left: v(130);

    .cbre-radio{
        margin-bottom: 5px;
    }
    .report-header{
        display: flex;
        justify-content: space-between;

        margin-bottom: v(50);

        > .left{
            width: v(650);
            margin-top: v(20);

            > h4{
                font-size: v(58);
                font-weight: 600;
                line-height: 1.1em;
                color: #003f2d;
                margin-bottom: v(20);
            }
            > p{
                color: #003f2d;
                font-size: v(16);
                white-space: pre-wrap;
            }
        }
        > .right{
            margin-top: v(30);
            width: v(300);
            height: v(169);
            background-color: #45866c;
            position: relative;
            background-image: url(https://img.mofyi.com/Ayto/image/20210916161549.jpg);
            background-size: cover;
            background-position: center center;
            > span:first-child{

                width: v(170);
                height: v(83);
                background-color: #02a557;
                display: block;
                position: absolute;
                right: 0;
                top: v(86);
                color: #fff;
                font-size: v(50);
                display: flex;
                align-items: center;
                justify-content: center;
                display: none;
            }
            > span:last-child{

                position: absolute;
                bottom: v(10);
                left: 0;
                color: #fff;
                text-align: center;
                display: block;
                width: 100%;
                font-size: v(14);
                display: none;
            }
        }
    }
    .report-form{

        > .radio-li{
            border-top: 1px solid #cecdce;
            padding: v(50) 0;

            > .title{
                position: relative;
                p{
                    font-size: v(36);
                    font-weight: 500;
                    color: #003F2D;
                }
                .text{
                    color: #666666;
                    font-size: v(16);
                    margin-bottom: v(20);
                    display: block;
                }
                .num{
                    position: absolute;
                    top: 0;
                    color: #becac8;
                    right: calc(100% + 5px);
                    font-weight: 600;
                    font-size: v(18);
                }
            }
        }
        > .fail-li{
            > .title{
                p, .text, .num{
                    color: #cc0100;
                }
            }
        }
        > .textarea-li{

            textarea{
                width: 100%;
                background-color: #c3dbd2;
                color: #00583F;
                padding: v(15);
                outline: none;
                border: 1px solid #57b57c;
                display: block;
                margin-bottom: v(20);
                resize: vertical;
                &::-webkit-input-placeholder{
                    color: #00583F;
                }
            }
        }
    }

    .done-style{
        text-align: center;
        > .iconfont{
            color: #16bc6b;
            font-size: v(150);
        }
        > .text{
            margin-top: v(50);
            font-size: v(60);
            color: #03583e;
            margin-bottom: v(50);
        }

        .theme-btn{
            padding: v(20) v(40);
            font-size: v(30);
            i{
                font-size: v(32);
            }
        }
    }
}




@media (max-width: 1250px) {
    $fontScale: 1.3;
    .report-content{
        padding-right: v(300);
    }
    .report-block{
        width: 100%;

        .report-header > .left > p{
            font-size: v(16 * $fontScale);
        }
    }
}

@media (max-width: 960px) {

    .report-content{
       padding-left: 0;
       padding-right: 0;
       width: 86%;
       margin: 0 auto;
    }
    .report-block{
        padding-left: 0;

        .report-header > .left {
            width: 75%;
            > h4{
                font-size: v(81);
            }
            > p{
                font-size: v(30);
            }
        }
        .report-form > .radio-li > .title{
            p{
                font-size: v(64);
            }
            .text{
                font-size: v(30);
            }
            .num{
                font-size: v(30);
            }
        }
    }

}

@media (max-width: 560px) {
    $scale: 2;

    .report-block{

        .cbre-radio{
            display: block;
        }
        .report-header {
            background-color: #f4f4f5;
            position: relative;
            padding: v(100);
            padding-bottom: v(140);
            > .left {
                width: 100%;
                > h4{
                    font-size: v(81 * $scale);
                    margin-bottom: v(30);
                }
                > p{
                    font-size: v(30 * $scale);
                }
            }
            > .right{
                position: absolute;
                bottom: v(-250);
                right: v(100);
                width: v(600);
                height: v(338);

                > span:first-child{
                    width: v(131 * $scale);
                    height: v(63 * $scale);
                    font-size: v(30 * $scale);
                }
            }
        }
        .report-form > .radio-li > .title{
            p{
                font-size: v(45 * $scale);
            }
            .text{
                font-size: v(30 * $scale);
            }
            .num{
                font-size: v(30 * $scale);
            }
        }

        .theme-btn{
            width: 100%;
            margin-top: v(30);
            height: v(180);
        }


        .done-style{
            text-align: center;
            padding-top: v(200);
            > .iconfont{
                color: #16bc6b;
                font-size: v(450);
            }
            > .text{
                margin-top: v(50);
                font-size: v(120);
                color: #03583e;
                margin-bottom: v(100);
            }

            .theme-btn{
                padding: v(40) v(40);
                font-size: v(70);
                height: v(210);
                i{
                    font-size: v(90);
                }
            }
        }
    }


}
