.write-block{
    width: 100%;
    padding: v(50) v(140);


    .el-form-item{
        margin-bottom: 0;
    }
    .el-form-item__content{
        line-height: 1.3em;
    }

    .title{
        text-align: center;
        color: #00feae;
        margin-bottom: v(20);
        h3{
            font-size: v(36);
            font-weight: normal;
            margin-bottom: v(20);
            i{
                font-size: v(50);
                vertical-align: text-bottom;

            }
        }
        h4{
            font-weight: normal;
            font-size: v(27);
        }
    }
    .line1{
        width: 100%;
        display: flex;
        align-items: center;
        @include linearGradient(45deg, #183a36 0%, #1c473d 100%);
        padding: v(15) 0;
        margin-bottom: v(20);
        > p{
            width: calc(100% - 150px);
            padding-left: v(50);
            color: #fff;
            font-size: v(34);
            font-weight: bold;
        }
        > img{
            width: 150px;
        }
    }
    .cbre-input{
        margin-bottom: v(35);
    }
    .cbre-checkbox{
        margin-bottom: v(50);
    }

    .btn-line{
        text-align: center;
        .theme-btn{
            width: v(200);
            font-size: 16px;
            margin: 0 auto;
        }
    }


}




@media (max-width: 1250px) {
    $fontScale: 1.3;

}

@media (max-width: 960px) {

    .write-block{
        padding-top: v(200);

        .title{
            margin-bottom: v(80);
            h3{
                font-size: v(86);
                font-weight: normal;
                margin-bottom: v(20);
                i{
                    font-size: v(100);
                    vertical-align: text-bottom;

                }
            }
            h4{
                font-weight: normal;
                font-size: v(67);
            }
        }
        .line1{
            margin-bottom: v(80);
            > p{
                font-size: v(90);
            }
        }
        .cbre-input{
            margin-bottom: v(50);
        }

        .btn-line {
            text-align: center;
            .theme-btn{
                width: 100%;
                height: v(115);
                margin-bottom: v(30);
            }
        }
    }
}

@media (max-width: 560px) {
    $scale: 2;

    .write-block{
        padding-top: v(200 * $scale);

        .title{
            margin-bottom: v(80);
            h3{
                font-size: v(136);
                font-weight: normal;
                margin-bottom: v(20);
                i{
                    font-size: v(150);
                    vertical-align: text-bottom;

                }
            }
            h4{
                font-weight: normal;
                font-size: v(97);
            }
        }
        .line1{
            margin-bottom: v(80);

            > img{
                width: 100px;
            }
            > p{
                padding-left: v(100);
                width: calc(100% - 100px);
                font-size: v(90);
            }
        }
        .cbre-input{
            margin-bottom: v(50 * $scale);
        }

        .btn-line {
            .theme-btn{
                width: 100%;
                height: v(115 * $scale);
                margin-bottom: v(30 * $scale);
            }
        }
    }
}
