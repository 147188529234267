.register-block{
    width: 100%;
    padding: v(70) v(140);


    .el-form-item{
        margin-bottom: 0;
    }
    .el-form-item__content{
        line-height: 1.3em;
    }

    .title{
        h3{
            font-size: v(45);
            color: #fff;
            font-weight: 600;
        }
        h4{
            font-size: v(34);
            color: #fff;
            font-weight: normal;

            > span{
                font-size: 120%;
            }
        }
    }
    .cbre-input{
        margin-bottom: v(35);
    }
    .cbre-checkbox{
        margin-bottom: v(50);
    }
    .email-tips{
        font-size: 14px;
        color: #fff;
        margin-top: v(-20);
        i{
            vertical-align: text-top;
        }
    }
    .btn-line{
        .theme-btn{
            font-size: 16px;
        }
        span{
            font-size: 16px;
            margin-left: 10px;
            color: #fff;
            a{
                color: #1AA763;
                display: inline-block;
                padding-bottom: 3px;
                border-bottom: 1px solid #6abe28;
            }
        }
    }


}




@media (max-width: 1250px) {
    $fontScale: 1.3;

}

@media (max-width: 960px) {

    .register-block{
        padding-top: v(200);

        .title{
            text-align: center;
            margin-bottom: v(80);
            h3{
                font-size: v(116);
            }
            h4{
                font-size: v(87);
            }
        }
        .cbre-input{
            margin-bottom: v(50);
        }
        .email-tips{
            margin-top: v(-30);
            margin-bottom: v(35);
        }

        .btn-line {
            text-align: center;
            .theme-btn{
                width: 100%;
                height: v(115);
                margin-bottom: v(30);
            }
        }
    }
}

@media (max-width: 560px) {
    $scale: 2;

    .register-block{
        padding-top: v(200 * $scale);

        .title{
            margin-bottom: v(80  * $scale);
            h3{
                font-size: v(116 * $scale);
            }
            h4{
                font-size: v(87 * $scale);
            }
        }
        .cbre-input{
            margin-bottom: v(50 * $scale);
        }
        .email-tips{
            margin-top: v(-30 * $scale);
            margin-bottom: v(35 * $scale);
        }

        .btn-line {
            .theme-btn{
                width: 100%;
                height: v(115 * $scale);
                margin-bottom: v(30 * $scale);
            }
        }
    }
}
