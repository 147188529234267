@import "https://file.mofyi.com/fonts/Futura/stylesheet.css";
html{
    height: 100%;
}
body {
    font-family: 'Futura', Microsoft YaHei, Arial, Verdana, sans-serif;
    font-size: 14px;
}
button, input, select, textarea {
    font-family: 'Futura', Microsoft YaHei, Arial, Verdana, sans-serif;
}

/* 基本布局样式 */
.wrap{
    width: 100%;
    background-image: url(https://img.mofyi.com/Ayto/image/20210802103452.jpg);
    background-size: cover;
    background-position: top center;
    overflow-x: hidden;
    transition: .2s;
}
.white-wrap{
    background-image: none;
    background-color: #eaeeed;
}

.main{
    height: 100%;
}
.content{
    width: 100%;
    min-height: 100vh;
    padding-top: v(205);
    padding-bottom: v(80);
}
// 窗口
.window{
    width: v(1080);
    margin: 0 auto;
    position: relative;
    z-index: 2;

    > .window-main{
        position: relative;
        width: 100%;
        min-height: calc(100vh - 3rem);
        background-image: url(https://img.mofyi.com/Ayto/image/20210805142033.png);
        background-size: 100% 100%;
        border-top: 1px solid #27835d;

        > .close-btn{
            position: absolute;
            right: v(29);
            top: v(13);
            cursor: pointer;

            i{
                font-size: v(25);
                color: #1AA763;
            }
        }

        &::after, &::before{
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 90%;
            background-image: linear-gradient(180deg, #27835d 60%, rgba(#27835d, 0));
        }
        &::before{
            left: auto;
            right: 0;
        }
    }
}


/* 全局通用组件样式 */
// 面包屑
.crumbs{
    color: #999;
    font-size: 14px;
    margin-bottom: v(20);
    a{
        display: inline-block;
        color: #999;
        border-bottom: 2px solid #1AA763;
    }
    span{
        display: inline-block;
        border-bottom: 2px solid transparent;
    }
    .fa{
        margin: 0 v(20);
    }
}
.test{
    margin-top: 2rem;
    font-size: 1rem;
    text-align: center;
    width: 100%;
    color: #fff;
}


.canvas-bg-login{
    position: absolute;
    z-index: 1;
    top: v(100);
    right: v(200);
    width: 750px;
    height: 500px;
}

.canvas-bg-home{
    position: absolute;
    z-index: 1;
    top: 50%;
    margin-top: -250px;
    right: v(200);
    width: 750px;
    height: 500px;
}

.canvas-bg-live{
    position: absolute;
    z-index: 1;
    top: 0;
    margin-top: -250px;
    left: v(100);
    width: 750px;
    height: 500px;
}


.cbre-alert{
    position: fixed;
    z-index: 201;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    border: 1px solid #29755b;
    padding: 30px 50px;
    min-width: 320px;
    text-align: center;
    background-color: rgba(#07553c, .8);
    color: #fff;

    .close{
        position: absolute;
        right: 7px;
        top: 7px;

        i{
            font-size: 20px;
            cursor: pointer;
        }
    }
    .icon{
        margin-bottom: 10px;
        i{
            color: #fff;
            font-size: 30px;
        }
    }
    .text{
        font-size: 14px;
    }
}

.g-tips{
    position: fixed;
    top: v(170);
    right: 0;
    width: 100%;
    padding-left: v(555);
    padding-right: v(200);
    text-align: left;
    color: #fff;
    z-index: 88;
    font-size: 14px;
    display: flex;
    align-items: center;
    span:first-child{
        color: #05914e;
        width: 50px;
    }
    span:last-child{
        width: calc(100% - 50px);
        color: #fff;
        overflow: hidden;
        position: relative;
        display: block;
        height: 20px;
        strong{
            line-height: 20px;
            position: absolute;
            width: auto;
            height: 100%;
            top: 0;
            left: 0;
            font-weight: normal;
            display: block;
            white-space: nowrap;
        }
    }
}
.video-pc-tips{
    position: absolute;
    top: v(-80);
    left: 0;
    padding: 0;
    width: 100%;
}

.white-wrap{
    .g-tips{
        span:last-child{
            color: #003f2d;
        }
    }
}


@media (max-width: 1250px) {
    $fontScale: 1.3;
    .crumbs{
        font-size: 12px;
    }
    .g-tips{
        padding-left: v(525);
    }
}

@media (max-width: 960px) {

    .content{
        min-height: 100vh;
        padding-top: v(155);
    }
    .g-tips{
        width: 100%;
        right: auto;
        left: 0;

        padding: 0 v(70);


        top: v(550);
        font-size: 14px;
        text-align: left;
        @include textOver;
    }
    .video-mobile-tips{
        position: relative;
        top: 0;
        left: 0;
        padding-left: v(50);
        padding-right: 0;
        width: 100%;
        height: 100%;
        line-height: 100%;
    }
    .local-mobile-tips{
        position: relative;
        top: 0;
        left: 0;
        padding-left: v(50);
        padding-right: 0;
        width: 100%;
        height: 100%;
        line-height: 100%;
    }
    .crumbs{
        display: none;
    }
    .window{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 102;
        width: 100%;
        height: 100%;
        overflow-y: auto;

        background-color: #054032;

        .window-main{
            border: none;
            &::after, &::before{
                display: none;
            }

            .close-btn{
                position: fixed;
                z-index: 105;
                top: v(35);
                right: v(35);
                padding: v(15);
                background-color: #144839;

                i{
                    font-size: v(50);
                }
            }
        }
    }


}

@media (max-width: 560px) {
    $scale: 2;

    .content{
        min-height: 100vh;
        padding-top: v(310);
    }
    .g-tips{
        width: 100%;
        right: auto;
        left: 0;
        padding: 0 v(140);
        // top: v(700);
        top: v(450);

        font-size: 12px;
        @include textOver;
    }
    .video-mobile-tips{
        position: relative;
        top: 0;
        left: 0;
        padding-left: v(50);
        padding-right: 0;
        width: 100%;
        height: 100%;
        line-height: 100%;
    }
    .local-mobile-tips{
        position: relative;
        top: 0;
        left: 0;
        padding-left: v(50);
        padding-right: 0;
        width: 100%;
        height: 100%;
        line-height: 100%;
    }

    .window{
        .window-main{
            .close-btn{
                top: v(35 * $scale);
                right: v(35 * $scale);
                i{
                    font-size: v(50 * $scale);
                }
            }
        }
    }

    .canvas-bg-home{
        right: 0;
    }
}
